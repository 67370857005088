import http from "@/utils/request";

// 获取列表
export function getList(data) {
    return http({
      method: "post",
      url: "/specialShopActivity/getActivityList.nd",
      data
    });
  }

//   获取营列表筛选条件
export function getYxList(data) {
    return http({
        method: "post",
        url: "/specialShopActivity/getDictBytype.nd",
        data
    });
}

// 审批状态

export function getStatusList(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getCheckStatus.nd",
        data
    });
}
// export function getExport(data) {
//     return http({
//         url: 'cts/ctsApi.nd',
//         method: 'get',
//         params:data,
//         contentType: "application/json;charset=UTF-8",
//         type: 'stringfy',
//         responseType: 'blob'
//     })
// }



// 导出

export function getExport(data) {
    return http({
        url: '/specialShopActivity/exportActivityList.nd',
        method: 'POST',
        params:data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        responseType: 'blob'
    })
}

//  删除
export function delLine(data) {
    return http({
        method: "post",
        url: "/specialShopActivity/deleteActivityById.nd",
        data
    });
}