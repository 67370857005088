
import { getYxList } from './../api'
import { getofficeList, getDLlnumSeach, getMatklByShop, flowStartActivity } from './api'
import axios from "axios";
import { publicPath } from "@/common/constant.js";
import moment from 'moment';
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  name: "",
  data() {
    return {
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
      {
        path: "/storeActivities",
        name: "storeActivities",
        title: "专卖店市场活动"
      },
      {
        path: "/storeActivities/addOredit",
        name: "addOredit",
        title: "活动新增"
      }
      ],
      pageLoadFlag: false,
      detilInfo: {},
      columns: [
        {
          title: "物料组",
          dataIndex: "matklName",
          width: 400,
          scopedSlots: { customRender: 'type' },
        },
        {
          title: "预计销售额（万元）",
          dataIndex: "planSaleMoney",
          width: 400,
          scopedSlots: { customRender: 'num' },
        },
      ],
      selectedRowKeys: [],
      tableData: [], // 物料组数据
      fileList: [],// 认筹毒箭
      fileIds: '', // 认筹附件id
      activefileList: [],// 活动方案附件
      activefileId: '',// 活动方案id
      saleList: [],// 销售现场附件
      saleFileId: '',// 销售现场附件id
      shareList: [],// 经验分享附件
      shareFileId: '',// 经验分享附件id
      previewVisible: false,
      previewImage: '',
      marketCenterList: [], // 营销中心数据
      marketCenterId: '', //营销中心id
      marketCenter: '',
      officeList: [],// 办事处数据
      officeName: '',// 办事处
      officeId: '', // 办事处id
      timeStart: '', // 活动时间
      endTime: '', // 结时间
      statusList: [], // 审批状态数据
      statusName: '', //审批状态

      FXDLlnum: '', // 模糊搜索 代理商id
      FXDLlist: [], // 模糊搜索出来的数据
      FXList: [],
      FXName: [],
      processInstId: '',
      FXID: '',
      therm: '',// 主题
      address: '',// 地址
      targetAmount: '', // 目标金额
      totalInvestment: "", // 投入合计
      advertisement: '',// 待制作广告
      visible: false,
      MarketTableList: [],// 带出来的物料组
      fetching: false
    };
  },
  watch: {
    "targetAmount": function (val) {
      this.targetAmount = this.targetAmount.replace(/[^0-9.]/g, '')
    },
    'FXDLlnum': function () {
      if (!this.FXDLlnum) {
        this.tableData = []
      }
    }
  },

  mounted() {
    this.getHDType()
  },

  methods: {
    onGiveType(e){
      this.marketCenterId = e.target.value
    },
    // 开始时间选择不能选择今天之前的日期
    disabledDate(current) {
      return current && current < moment().endOf('day');
    },

    changeThem(e) {
      if (e.target.value.length >= 40) {
        this.$message.warning('最多输入40个文字')
        return false
      }
    },
    changeplace(e) {
      if (e.target.value.length >= 40) {
        this.$message.warning('最多输入40个文字')
        return false
      }
    },
    changeguanggao(e) {
      if (e.target.value.length >= 40) {
        this.$message.warning('最多输入40个文字')
        return false
      }
    },
    // 全选 -- 把数据源赋给select绑定的值
    selectAll() {
      let arr = [];
      this.FXList.forEach((item) => {
        arr.push(item.code);
      });
      this.FXName = arr;
    },
    // 清空 -- 清空select绑定的值
    clearAll() {
      this.FXName = [];
    },
    goback() {
      window.close()
      this.$router.push({ path: '/storeActivities' });
    },
    changeDl(value, e) {
      this.dlId = value
    },

    // 输入模糊搜索代理商
    getDLList(value) {
      this.fetching = true
      this.FXDLlnum = value
      let data = {
        search: this.FXDLlnum
      }
      getDLlnumSeach(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data)
        } else {
          data = res.data;
        }
        this.FXDLlist = [...new Set(data.data)]; //缓存列表数据，前端分页
        this.fetching = false
      })
    },
    //查询数据
    search() {
      this.getMarktl()
    },
    getMarktl() {
      if (this.dlId) {
        let data = {
          shopId: this.dlId
        }
        getMatklByShop(data).then(res => {
          this.MarketTableList = res.data.data
          this.tableData = []
          this.MarketTableList.forEach(marItem => {
            this.tableData.push({
              matklName: marItem.matklName,
              matklId: marItem.matklId,
              planSaleMoney: 0,
              isEdited: true
            })
          })
        })
      }
    },
    // 更改营销
    handleChangeshopList(item,index) {
      //  更改选项的时候判断当前数据和接口数据的值是否一致 赋值新的id
      this.MarketTableList.forEach(marItem => {
        this.tableData.forEach(item => {
          if (marItem.matklName == item.matklName) {
            item.matklId = marItem.matklId
          }
        })
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 开始时间更改
    handleTimeChange(e, defaultTime) {
      this.timeStart = defaultTime;
    },
    // 结束时间更改
    handleendtime(e, defaultTime) {
      this.endTime = defaultTime;
    },
    //获取活动类别数据
    getHDType() {
      let data = {
        categoryName: 'specialActivityType'
      }
      getYxList(data).then(res => {
        this.marketCenterList = res.data.data
      })
    },

    // 根据营销中心获取办事处数据
    getOffice(id) {
      let data = {
        orgCode: id
      }
      getofficeList(data).then(res => {
        this.officeList = res.data.data
        this.officeName = res.data.data[0].name
        this.officeId = res.data.data[0].id
      })
    },
    handleChangeofficeList(value, e) {
      this.officeName = value
      this.officeId = e.key
    },

    // 复制行数据
    copy() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请至少选择一项数据!')
      } else if (this.selectedRowKeys.length > 1) {
        this.$message.warning('只能一条数据进行复制!')
      } else {
        let aa = {}
        this.tableData.forEach((item, index) => {
          this.selectedRowKeys.forEach(i => {
            if (index == i) {
              aa = {
                isEdited: true,
                matklId:  this.tableData[index].matklId,
                matklName:  this.tableData[index].matklName,
                planSaleMoney:  this.tableData[index].planSaleMoney,
               
              }
            }
          })
        })
        this.tableData.push(aa)
      }
    },

    //删除行数据
    delLine() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请至少选择一项数据')
      } else {
        this.tableData = this.tableData.filter((item, index) => !this.selectedRowKeys.includes(index))
        this.selectedRowKeys = []
      }
    },
    // 勾选数据
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    // 添加行
    addline() {
      this.tableData.push(
        {
          matklName: "",
          matklId: "",
          planSaleMoney: 0,
          isEdited: true
        }
      )
    },

    // 认筹上传附件
    beforeUpload(file) {
      if ((file.size) / 1024 > 5120) {
        this.$message.warning('附件大小不能大于5M')
        return false;
      } else {
        // 上传附件   调接口
        let formData = new FormData()
        formData.append('file', file);
        const BASE_API = publicPath;
        let config = {
          headers: { 'content-type': 'multipart/form-data', },
        }
        this.pageLoadFlag = true
        axios.post(BASE_API + '/specialShopActivity/uploadFile.nd', formData, config).then(res => {
          let resResult = res.data
          if (resResult.message == '上传成功') {
            this.fileList = [...this.fileList, file]
            this.fileList = this.fileList.slice(-1);
            this.fileList.forEach(item => {
              item.size2 = ((item.size) / 1024).toFixed(2)
            })
            this.fileIds = res.data.businessId
            this.pageLoadFlag = false
          } else {
            this.fileList = []
            this.$message.warning(resResult.msg, 3)
            this.pageLoadFlag = false
            return
          }

        })
        return false;
      }
    },
    handleChange({ fileList }) {
      let fileList1 = fileList;
      this.fileList = fileList1.slice(-1);
      this.fileList.forEach(item => {
        item.size2 = ((item.size) / 1024).toFixed(2)
      })
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      // this.fileIds.splice(index, 1)
      this.fileIds = ''
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (file.url) {
        window.open(file.url)
      } else {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      }
    },
    handleDownload(file) {
      this.$message.warn('您点击了下载')
    },
    // 活动上传附件  附件2
    beforeUpload2(file) {
      if ((file.size) / 1024 > 5120) {
        this.$message.warning('附件大小不能大于5M')
        return false;
      } else {
        // 上传附件   调接口
        let formData = new FormData()
        formData.append('file', file);
        const BASE_API = publicPath;
        let config = {
          headers: { 'content-type': 'multipart/form-data', },
        }
        this.pageLoadFlag = true
        axios.post(BASE_API + '/specialShopActivity/uploadFile.nd', formData, config).then(res => {
          let resResult = res.data
          if (resResult.message == '上传成功') {
            this.activefileList = [...this.activefileList, file]
            this.activefileList = this.activefileList.slice(-1);
            this.activefileList.forEach(item => {
              item.size2 = ((item.size) / 1024).toFixed(2)
            })
            this.activefileId = res.data.businessId
            this.pageLoadFlag = false
          
          } else {
            this.activefileList = []
            this.$message.warning(resResult.msg, 3)
            this.pageLoadFlag = false
            return
          }

        })

        return false;
      }
    },
    handleChange2({ fileList }) {
      let fileList1 = fileList;
      this.activefileList = fileList1.slice(-1);
      this.activefileList.forEach(item => {
        item.size2 = ((item.size) / 1024).toFixed(2)
      })

    },
    handleRemove2(file) {
      const index = this.activefileList.indexOf(file);
      const newFileList = this.activefileList.slice();
      newFileList.splice(index, 1);
      this.activefileList = newFileList;
      // this.activefileId.splice(index, 1)
      this.activefileId = ''
    },

    //销售现场附件
    beforeUpload3(file) {
      if ((file.size) / 1024 > 5120) {
        this.$message.warning('附件大小不能大于5M')
        return false;
      } else {
        // 上传附件   调接口
        let formData = new FormData()
        formData.append('file', file);
        const BASE_API = publicPath;
        let config = {
          headers: { 'content-type': 'multipart/form-data', },
        }
        this.pageLoadFlag = true
        axios.post(BASE_API + '/specialShopActivity/uploadFile.nd', formData, config).then(res => {
          let resResult = res.data
          if (resResult.message == '上传成功') {
            this.saleList = [...this.saleList, file]
            this.saleList = this.saleList.slice(-1);
            this.saleList.forEach(item => {
              item.size2 = ((item.size) / 1024).toFixed(2)
            })
            this.saleFileId = res.data.businessId
            this.pageLoadFlag = false
        
          } else {
            this.saleList = []
            this.$message.warning(resResult.msg, 3)
            this.pageLoadFlag = false
            return
          }

        })

        return false;
      }
    },
    handleChange3({ fileList }) {
      // this.fileList = fileList;
      let fileList1 = fileList;
      this.saleList = fileList1.slice(-1);
      this.saleList.forEach(item => {
        item.size2 = ((item.size) / 1024).toFixed(2)
      })

    },
    handleRemove3(file) {
      const index = this.saleList.indexOf(file);
      const newFileList = this.saleList.slice();
      newFileList.splice(index, 1);
      this.saleList = newFileList;
      // this.saleFileId.splice(index, 1)
      this.saleFileId = ''
    },

    // 经验分享附件上传

    beforeUpload4(file) {
      if ((file.size) / 1024 > 5120) {
        this.$message.warning('附件大小不能大于5M')
        return false;
      } else {
        // 上传附件   调接口
        let formData = new FormData()
        formData.append('file', file);
        const BASE_API = publicPath;
        let config = {
          headers: { 'content-type': 'multipart/form-data', },
        }
        this.pageLoadFlag = true
        axios.post(BASE_API + '/specialShopActivity/uploadFile.nd', formData, config).then(res => {
          let resResult = res.data
          if (resResult.message == '上传成功') {
            this.shareList = [...this.shareList, file]
            this.shareList = this.shareList.slice(-1);
            this.shareList.forEach(item => {
              item.size2 = ((item.size) / 1024).toFixed(2)
            })
            this.shareFileId = res.data.businessId
            this.pageLoadFlag = false
            return
          } else {
            this.shareList = []
            this.$message.warning(resResult.msg, 3)
            this.pageLoadFlag = false
            return
          }

        })

        return false;
      }
    },
    handleChange4({ fileList }) {
      // this.fileList = fileList;
      let fileList1 = fileList;
      this.shareList = fileList1.slice(-1);
      this.shareList.forEach(item => {
        item.size2 = ((item.size) / 1024).toFixed(2)
      })

    },
    handleRemove4(file) {
      const index = this.shareList.indexOf(file);
      const newFileList = this.shareList.slice();
      newFileList.splice(index, 1);
      this.shareList = newFileList;
      // this.shareFileId.splice(index, 1)
      this.shareFileId = ''
    },
    // 上传附件代码结束

    // 提交
    confim() {
      if (!this.dlId) {
        this.$message.warning('请选择门店')
        return
      }
      if (!this.address) {
        this.$message.warning('请填写活动主题')
        return
      }
      if (!this.marketCenterId) {
        this.$message.warning('请选择活动类别')
        return
      }
      if (!this.timeStart) {
        this.$message.warning('请选择开始时间')
        return
      }
      if (!this.endTime) {
        this.$message.warning('请选择结束时间')
        return
      }
      // 开始时间转换时间戳
      let timestr = this.timeStart.replace(/-/g, '/');
      let timestamp = new Date(timestr).getTime();
      //结束时间转换时间戳
      let endTimeStr = this.endTime.replace(/-/g, '/');
      let endTimeSj = new Date(endTimeStr).getTime();
      if (timestamp > endTimeSj) {
        this.$message.warning('开始时间不能大于结束时间，请重新选择')
        return
      }
      if (this.tableData && this.tableData.length > 0) {
        for (var i = 0; i < this.tableData.length; i++) {
          if(!this.tableData[i].matklId){
            this.$message.warning('请将数据填写完整再提交')
            return false;
          }
          for (var j = i + 1; j < this.tableData.length; j++) {
            if (this.tableData[i].matklId == this.tableData[j].matklId) {
              this.$message.warning('相同物料组只能提交一组数据')
              return false;
            }
          }
        }
      }

      let data = {
        id: 0,
        shopInfoId: this.dlId,
        activityTheme: this.address,//活动主题
        activityLabelId: this.marketCenterId,//活动类别id
        startTime: this.timeStart,//开始时间
        endTime: this.endTime,//结束时间
        saleInfoList: this.tableData,
        rcxcAttach: this.fileIds, // 认筹附件
        hdfaAttach: this.activefileId,//活动方案附件
        xsxcAttach: this.saleFileId,//销售现场附件
        jyfxAttach: this.shareFileId,//经验分享附件

      }
      this.pageLoadFlag = true
      flowStartActivity(data).then(res => {
        if (res.data && res.data.code == 0) {
          this.$message.success(res.data.msg)
          window.opener.location.reload();
          this.pageLoadFlag = false
          this.timer = setTimeout(() => {  //设置延迟执行1
            this.goback()
          }, 2000);
        } else {
          this.$message.warning(res.data.msg)
          this.pageLoadFlag = false
        }
      })
    }
  }

};