

import http from "@/utils/request";

// 获取列表
export function getofficeList(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getOffice.nd",
        data
    });
}

  
export function getDLlnumSeach(data) {
    return http({
        method: "post",
        url: "/specialShopActivity/getSpecialShop.nd",
        data
    });
}
// 选择专卖店查询物料组
export function getMatklByShop(data) {
    return http({
        method: "post",
        url: "/specialShopActivity/getMatklByShop.nd",
        data
    });
}

// 保存-编辑
export function saveActivity(data) {
    return http({
        method: "post",
        url: "/specialShopActivity/saveActivity.nd",
        data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}

// 保存-新增
export function flowStartActivity(data) {
    return http({
        method: "post",
        url: "/specialShopActivity/flowStartActivity.nd",
        data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}